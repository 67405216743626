<template>
  <router-view></router-view>
</template>
<script>

</script>
<style lang="scss">
/* 更改进度条颜色 */
#nprogress .bar {
  background: #be2edd !important;
  height: 3px !important;
}
</style>
